<template>
  <a-spin :spinning="state.page_loading">
    <sdPageHeader title="Gönderi Listesi (Cargong)" />
    <Main>
      <sdCards headless>
        <a-table
          :data-source="state.shipments"
          :columns="state.columns"
          :pagination="state.pagination"
          row-key="id"
          @change="state.handleTableChange"
        >
          <template
            #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          >
            <div style="padding: 8px">
              <a-input
                ref="searchInput"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="state.handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="state.handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon>
                  <SearchOutlined />
                </template>
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="state.handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
          </template>
          <template #filterIcon="filtered">
            <search-outlined
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
          </template>
          <template #tracking_no_render="{ record }">
            <span v-if="record.tracking_url && record.tracking_url.length > 0">
              <a
                :href="record.tracking_url"
                target="_blank"
              >{{ record.tracking_no }}</a>
            </span>
            <span v-else>{{ record.tracking_no }}</span>
          </template>
        </a-table>
      </sdCards>
    </Main>
  </a-spin>
</template>
<script>
import { Main } from './../styled'
import { SearchOutlined } from '@ant-design/icons-vue'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { prepareTableData } from '@/utility/utility'
import restMethods from '@/utility/restMethods'

export default defineComponent({
  name: 'CargongShipmentList',
  components: {
    Main,
    SearchOutlined,
  },
  setup() {

    const searchInput = ref()

    const state = reactive({
      shipments: [],
      searchText: '',
      searchedColumn: '',
      page_loading: true,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50', '100', '200'],
      },
      columns: [
        {
          title: 'Müşteri',
          dataIndex: 'domain', // filtre olarak backend'de kullanılıyor
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                searchInput.value.focus()
              }, 100)
            }
          },
        },
        {
          title: 'Tarih',
          dataIndex: 'created_at',
        },
        {
          title: 'Sipariş No',
          dataIndex: 'cargo_key',
        },
        {
          title: 'Kargo',
          dataIndex: 'shipping_company_name', // filtre olarak backend'de kullanılıyor
        },
        {
          title: 'Gönderi No',
          dataIndex: 'request_number',
        },
        {
          title: 'Takip No',
          slots: {
            customRender: 'tracking_no_render',
          },
        },
      ],
      handleSearch: (selectedKeys, confirm, dataIndex) => {
        confirm()
        state.searchText = selectedKeys[0]
        state.searchedColumn = dataIndex
      },
      handleReset: (clearFilters) => {
        clearFilters()
        state.searchText = ''
      },
      handleTableChange: (page, filters, sorter) => {
        state.loadPageData(prepareTableData(page, filters, sorter))
      },
      loadPageData: (tableData = undefined) => {

        tableData = typeof tableData === 'undefined' ? prepareTableData(state.pagination) : tableData

        state.page_loading = true
        restMethods.postForNoc('/shipment-list-for-cargong', {
          ...tableData,
        }).then(response => {
          if ( ! response.hasError()) {

            const data = response.getData()

            state.shipments = data.shipments
            state.pagination = data.pagination

            state.columns = state.columns.map((item) => {

              switch (item.dataIndex) {

                case 'shipping_company_name':

                  item.filters = data.cs_company_codes.map((item) => ({
                    text: item,
                    value: item,
                  }))

                  break

              }

              return item

            })

          }
          state.page_loading = false
        })
      },

    })

    onMounted(() => {
      state.loadPageData()
    })

    return {

      state,

      searchInput,

    }

  },
})
</script>