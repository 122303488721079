export const prepareTableData = (page, filters = undefined, sorter = undefined) => {

  return {
    pagination: {
      page: page.current ?? 1,
      take: page.pageSize ?? 100,
    },
    filters: typeof filters === 'undefined' ? [] : filters,
    sort: typeof filters === 'undefined' ? {
      field: '',
      desc: false,
    } : {
      field: sorter.field,
      desc: sorter.order === 'descend',
    },
  }

}